<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
    >
      <b-spinner
        class="align-middle"
      />
    </div>
    <div v-if="!loading">
      <div class="table-responsive">
        <table class="table card-table">
          <thead>
            <tr>
              <th>
                {{ $t('opportunities.editOpportunity.document.list.table.th.image') }}
              </th>
              <th>
                {{ $t('opportunities.editOpportunity.document.list.table.th.title') }}
              </th>
              <th>
                {{ $t('opportunities.editOpportunity.document.list.table.th.requireKyc') }}
              </th>
              <th>
                {{ $t('opportunities.editOpportunity.document.list.table.th.requireQualification') }}
              </th>
              <th>
                {{ $t('opportunities.editOpportunity.document.list.table.th.createdAt') }}
              </th>
              <th>
                {{ $t('opportunities.editOpportunity.document.list.table.th.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for=" (field) in currentOpportunity.documents"
              :key="field.id"
            >
              <td>
                <div
                  class="image-wrapper"
                  @click="downloadDocument(field)"
                >
                  <img
                    class="ui-w-40 d-block link ion ion-ios-add-circle-outline"
                    :src="getImageIcon(field)"

                    alt="Document image"
                  >
                  <i class="ion ion-ios-add-circle-outline" />
                </div>
              </td>
              <td>{{ field.title }}</td>
              <td>
                <b-badge :variant="field.requireKyc ? 'success' : 'danger'">
                  {{ field.requireKyc }}
                </b-badge>
              </td>
              <td>
                <b-badge :variant="field.requireQualification ? 'success' : 'danger'">
                  {{ field.requireQualification }}
                </b-badge>
              </td>
              <td>{{ field.createdAt | dateFilter }}</td>
              <td>
                <div class="d-flex">
                  <cp-button
                    icon="ios-arrow-round-down"
                    variant="outline-primary"
                    @click="downloadDocument(field)"
                  >
                    {{ $t('opportunities.editOpportunity.document.list.downloadButton.label') }}
                  </cp-button>
                  <cp-button
                    variant="outline-none"
                    @click="edit(field)"
                  >
                    <i
                      class="ion ion-md-create"
                    />
                  </cp-button>
                  <cp-button
                    variant="outline-none"
                    @click="showDeleteModal(field)"
                  >
                    <i
                      class="ion ion-ios-trash"
                    />
                  </cp-button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center mt-5">
        <b-button
          variant="primary"
          size="lg"
          class="mb-5 pl-5 pr-5 d-inline-block"
          @click="addDocumentAction"
        >
          <i
            class="ion ion-ios-add-circle"
          />    {{ $t('opportunities.editOpportunity.document.list.addButton.label') }}
        </b-button>
      </div>

      <cp-confirm-modal
        ref="cpConfirmDeleteModal"
        :ok-text="$t('opportunities.editOpportunity.document.list.deleteConfirmation.deleteButton')"
        @onOk="runDeleteORequest"
      >
        <h3>
          {{ $t('opportunities.editOpportunity.document.list.deleteConfirmation.title') }}
          <span class="font-weight-light">'{{ opportunityDocumentSelected.title }}'</span>
        </h3>
        <p>
          {{ $t('opportunities.editOpportunity.document.list.deleteConfirmation.content') }}
        </p>
      </cp-confirm-modal>
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import staticPathGeneration from '~/mixins/static-path-generation';
import CpButton from '~/components/common/standalone-components/cp-button';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';

export default {
  name: 'EditOpportunitySummary',
  components: {
    CpButton,
    CpConfirmModal,
  },
  mixins: [staticPathGeneration],
  data() {
    return {
      isReady: false,
      contents: [],
      addModalOpen: false,
      opportunityDocumentSelected: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters('opportunities', ['currentOpportunity']),
    getImageIcon() {
      return ({ thumbnail, fileType }) => (
        fileType === 'application/pdf'
          ? this.getImagePath('fallback-pdf.jpg')
          : thumbnail || this.getImagePath('fallback-image.jpg')
      );
    },
  },
  created() {
  },
  methods: {
    ...mapActions('opportunities', ['getOpportunityById', 'deleteOpportunityDocument']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    addContentAction() {
      this.addModalOpen = true;
    },
    showDeleteModal(doc) {
      this.opportunityDocumentSelected = { ...doc };
      this.$refs.cpConfirmDeleteModal.show();
    },
    downloadDocument(doc) {
      this.$router.push({ name: 'ViewOpportunityDocumentModal', params: { docId: doc.id } });
    },
    async runDeleteORequest() {
      try {
        const { idIssuer: issuerId } = this.$route.params;
        this.loading = true;
        await this.deleteOpportunityDocument({
          issuerId,
          opportunityId: this.currentOpportunity.id,
          opportunityDocumentId: this.opportunityDocumentSelected.id,
        });
        await this.getOpportunityById({ issuerId, opportunityId: this.currentOpportunity.id, additionalFields: 'attributes,documents,contents,languages' });
      } catch (x) {
        this.CALL_ERROR_TOASTER('Error trying to delete document:', x);
      } finally {
        this.loading = false;
      }
    },
    addDocumentAction() {
      this.$router.push({ name: 'UpdateOpportunityDocumentModal', params: { docId: undefined } });
    },
    edit(doc) {
      this.$router.push({ name: 'UpdateOpportunityDocumentModal', params: { docId: doc.id } });
    },
  },
};
</script>
<style lang="scss">
.image-wrapper{
  position:relative;
  cursor:pointer;
  i{
    position:absolute;
    top:12px;
    left:12px;
  }
}

</style>
