<template>
  <b-modal
    id="confirmModal"
    ref="confirmModal"
    :ok-title="okText || $t('common.button.ok')"
    :size="size"
    :ok-only="okOnly"
    :cancel-title="cancelText || $t('common.button.cancel')"
    :ok-variant="okVariant"
    :content-class="contentClass"
    :body-class="bodyClass"
    :footer-class="footerClass"
    :scrollable="scrollable"
    cancel-variant="default"
    :ok-disabled="isOkDisabled"
    @ok="$emit('onOk', item)"
    @hide="hideEventHandler('onCancel',$event)"
  >
    <template slot="modal-title">
      <i :class="`ion ion-${titleIcon}`" />
      <span :class="titleStyle"> {{ title }}</span>
    </template>
    <p
      v-if="slotWrapper"
      class="my-4"
    >
      <slot />
    </p>
    <slot name="slot-extended" />
  </b-modal>
</template>

<script>

export default {
  name: 'ConfirmModal',
  props: {
    title: {
      type: String,
      default: '',
    },
    titleIcon: {
      type: String,
      default: '',
    },
    titleStyle: {
      type: String,
      default: '',
    },
    okText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {},
    },
    okOnly: {
      type: Boolean,
      default: false,
    },
    okVariant: {
      type: String,
      default: 'primary',
    },
    bodyClass: {
      type: String,
      default: '',
    },
    footerClass: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    isOkDisabled: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      slotWrapper: false,
    };
  },
  mounted() {
    if (this.$slots.default && this.$slots.default.length) this.slotWrapper = true;
  },
  methods: {
    hideEventHandler(eventType, event) {
      if (event.trigger !== 'ok') {
        this.$emit(eventType, event);
      }
    },
    show() {
      this.$refs.confirmModal.show();
    },
  },
};
</script>
